import React from 'react';
import { useTranslation } from 'react-i18next';
import './cv.scss';

const Curriculum = () => {

    const { t } = useTranslation()

    return (
        <>
            <div id={"cv"} className={"col-12"}>
				<div id={"cv-in"}>
					<div className={"row"}>
						<div className={"col-12 col-title"}>
							<span className={"h2"}>{t("Personal Information")}</span>
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>{t("Family Name")}</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							Van de Polder
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>{t("Usual Name")}</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							Andre
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>{t("Email")}</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							avdpid@gmail.com 
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>{t("Year of Birth")}</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							1985
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>{t("Marital Status")}</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							{t("Unmarried")}
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>{t("Gender")}</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							{t("Male")}
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>{t("Nationality")}</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							{t("Dutch")}
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>{t("Drivers Licence")}</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							{t("Yes")}
						</div>
					</div>
					<div className={"row"}>
						<div className={"col-12 col-title"}>
							<span className={"h2"}>{t("Education")}</span>
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>2009 - 2015</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							{t("Edu04")}
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>2008 - 2009</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							{t("Edu03")}
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>2004 - 2008</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							{t("Edu02")}
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>2002 - 2003</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							{t("Edu01")}
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>1998 - 2002</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							{t("Edu00")}
						</div>
					</div>
					<div className={"row"}>
						<div className={"col-12 col-title"}>
							<span className={"h2"}>{t("Work Experience")}</span>
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>2012 - {t("Present")}</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							{t("Work06")}
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>2010-2011</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							{t("Work05")}
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>2002-2010</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							{t("Work04")}
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>2007</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							{t("Work03")}
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>2006</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							{t("Work02")}
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>2005/2006/2008</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							{t("Work01")}
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<strong>2002/2003/2004</strong>
						</div>
						<div className={"col-12 col-sm-6 col-md-8 col-lg-9"}>
							{t("Work00")}
						</div>
					</div>
					<div className={"row three-boxes"}>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<div className={"col-title"}>
								<span className={"h2"}>Codes</span>
							</div>
							(x)HTML(5)<br />
							CSS(3)<br />
							jQuery<br />
							React (starter)<br />
							PHP (reading)
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<div className={"col-title"}>
								<span className={"h2"}>{t("Programs")}</span>
							</div>
							Dreamweaver<br />
							Photoshop<br />
							Illustrator<br />
							Premiere Pro<br />
							After Effects<br />
							InDesign<br />
							phpStorm
						</div>
						<div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
							<div className={"col-title"}>
								<span className={"h2"}>{t("Other")}</span>
							</div>
							Windows<br />
							Mac<br />
							Microsoft Word<br />
							Microsoft Excel<br />
							Microsoft Powerpoint<br />
							Google Docs<br />
							Jira
						</div>
					</div>
				</div>
            </div>
        </>
    );
}

export default Curriculum;
