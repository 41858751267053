import React from 'react';
import { useTranslation } from 'react-i18next';
import './footer.scss';

const Footer = () => {

    const { t } = useTranslation();

    return (
        <footer>
            <div id={"footer-in"}>
		{/*<div id={"contact"}>
                    <div id={"contact-in"}>
                        <div className={"h3"}>{t('Contact Title')}</div>
                        <form id={"contact-form"} method={"POST"}>
                            <div className={"form-row"}>
                                <div className={"form-field half"}>
                                    <input type={"text"} name={"name"} placeholder={t('form-name')} />
                                </div>
                                <div className={"form-field half"}>
                                    <input type={"email"} name={"email"} placeholder={t('form-email')} />
                                </div>
                            </div>
                            <div className={"form-row"}>
                                <div className={"form-field"}>
                                    <textarea name={"message"} placeholder={t('form-message')} rows={"3"} />
                                </div>
                            </div>
                            <div className={"form-row"}>
                                <div className={"form-field"}>
                                    <input type={"submit"} value={t('form-send')} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>*/}
                <div id={"copyright"}>
                    &copy; Andre van de Polder
                </div>
                <div id={"social-media"}>
                    {t('Follow or connect with me on')}:
                    <ul>
                        <li>
                            <a href={"http://nl.linkedin.com/in/avdp85"} target={"_blank"} rel={"noopener noreferrer"}>
                                <i className={"fab fa-linkedin-in"}></i>
                            </a>
                        </li>
                        <li>
                            <a href={"http://instagram.com/avdp85"} target={"_blank"} rel={"noopener noreferrer"}>
                                <i className={"fab fa-instagram"}></i>
                            </a>
                        </li>
                        <li>
                            <a href={"http://www.facebook.com/AvdP85"} target={"_blank"} rel={"noopener noreferrer"}>
                                <i className={"fab fa-facebook-f"}></i>
                            </a>
                        </li>
                        <li>
                            <a href={"http://avdp85.deviantart.com/"} target={"_blank"} rel={"noopener noreferrer"}>
                                <i className={"fab fa-deviantart"}></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
