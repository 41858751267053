import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import WorkBlock from "./work-block";
import './home.scss';

const Home = () => {

    const { t } = useTranslation()

    const [ data, setData ] = useState( [] )

    let works = '/apis/portfolio.json';

    useEffect(() => {
        getItemsToList(works)
    }, [works])

    async function getItemsToList(url) {
        try {
            let response = await fetch(url)
            let json = await response.json()
            setData(json.portfolio)
        } catch (error) {
            console.log('No items to list')
        }
    }

    return (
        <>
            <div id={"intro"} style={{backgroundImage: "url('/images/bg-intro.jpg')"}}>
                <div id={"intro-text"}>
                    "{t('Simplicity is the ultimate sophistication')}"<br />
                    <span>– Leonardo da Vinci</span>
                </div>
            </div>
            <div id={"services"}>
                <div id={"services-in"}>
                    <div className={"service col-12 col-sm-4"}>
                        <div className={"service-title"}>
                            <i className={"fas fa-pencil-alt"}></i>{t('Design')}
                        </div>
                        <div className={"service-text"}>
                            {t('Service01')}
                        </div>
                    </div>
                    <div className={"service col-12 col-sm-4"}>
                        <div className={"service-title"}>
                            <i className={"fas fa-code"}></i>{t('Development')}
                        </div>
                        <div className={"service-text"}>
                            {t('Service02')}
                        </div>
                    </div>
                    <div className={"service col-12 col-sm-4"}>
                        <div className={"service-title"}>
                            <i className={"fas fa-mobile-alt"}></i>{t('Responsive')}
                        </div>
                        <div className={"service-text"}>
                            {t('Service03')}
                        </div>
                    </div>
                </div>
            </div>
            <div id={"about"} className={"col-12"}>
                <div id={"about-in"}>
                    <div id={"about-image"}></div>
                    <div id={"about-text"}>
                        {t('About me')}
                    </div>
                </div>
            </div>
            <div id={"works"}>
                {data.slice(0,4).map((work, index) =>
                    <WorkBlock key={index} title={work.workTitle} date={work.workDate} thumb={work.workThumb} image={work.workImage} />
                )}
            </div>
        </>
    );
}

export default Home;
