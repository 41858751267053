import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import WorkBlock from "./work-block";
import './portfolio.scss';

let selectedCategory;
let filteredPortfolio;

const Portfolio = () => {

    const { t } = useTranslation()

    const [ data, setData ] = useState( [] )
    const [ filteredLiat, setfilteredLiat ] = useState( [] )

    let portfolio= '/apis/portfolio.json';

    useEffect(() => {
        getItemsToList(portfolio)
    }, [portfolio])

    async function getItemsToList(url) {
        try {
            let response = await fetch(url)
            let json = await response.json()
            setData(json.portfolio)
            setfilteredLiat(json.portfolio)
        } catch (error) {
            console.log('No items to list')
        }
    }

    function filterWork(e) {
        let portfolioMenu = document.getElementById('works-filter').querySelectorAll('li')
        if(e.target.dataset.value !== selectedCategory) {
            selectedCategory = e.target.dataset.value
            filteredPortfolio = filteredLiat.filter((work) => (work.workTag === selectedCategory) ? work : '')
            setData(filteredPortfolio)
            portfolioMenu.forEach((list) => {
                list.classList.remove('selected')
            })
            e.target.classList.add('selected')
        } else {
            setData(filteredLiat)
            selectedCategory = ''
            e.target.classList.remove('selected')
        }
    }

    return (
        <>
            <div id={"works-overview"}>
            	<div id={"works-filter"}>
                    <div id={"works-filter-in"}>
                        <ul>
                            <li onClick={filterWork} data-value={"website"}>{t('Websites')}</li>
                            <li onClick={filterWork} data-value={"print"}>{t('Prints')}</li>
                            <li onClick={filterWork} data-value={"illustration"}>{t('Illustrations')}</li>
                            <li onClick={filterWork} data-value={"character"}>{t('Characters')}</li>
                            <li onClick={filterWork} data-value={"video"}>{t('Videos')}</li>
							{/*<li onClick={filterWork} data-value={"study"}>{t('Study')}</li>*/}
                        </ul>
                    </div>
                </div>
                <div id={"works-in"}>
                    {data.map((work, index) =>
                         <WorkBlock key={index} title={work.workTitle} date={work.workDate} thumb={work.workThumb} image={work.workImage} />
                    )}
                </div>
            </div>
        </>
    );
}

export default Portfolio;
