import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from './logo.svg';
import './header.scss';

const Header = () => {

    const { i18n } = useTranslation()

    let setLanguage = localStorage.getItem('language')

    const changeLanguage = (e) => {
        setLanguage =  e.target.innerText.toLowerCase()
        i18n.changeLanguage(setLanguage);
        localStorage.setItem('language', setLanguage)
    }
	
	function openMenu() {
		if(document.getElementById('navigation').classList.contains('open-menu')) {
			document.getElementById('navigation').classList.remove('open-menu')
		} else {
			document.getElementById('navigation').classList.add('open-menu')
		}
	}

    return (
        <header id="header">
            <div id={"header-in"}>
				<div id={"menu-btn"} onClick={openMenu}>
					<i className={"fas fa-bars"}></i>
				</div>
                <div id={"logo"}>
                    <NavLink to={"/"}>
                        <img src={logo} alt={"Andre van de Polder"}/>
                    </NavLink>
                </div>
                <div id={"language"}>
                    <ul>
                        <li><span onClick={changeLanguage} className={(setLanguage === "en") ? 'active' : ''}>en</span></li>
                        <li><span onClick={changeLanguage} className={(setLanguage === "nl") ? 'active' : ''}>nl</span></li>
                    </ul>
                </div>
                <nav id={"navigation"}>
                    <ul>
                        <li>
                            <NavLink to={"/CV"}>
                                CV
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/portfolio"}>
                                Portfolio
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default Header;
