import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import i18n from "i18next";
import '@fortawesome/fontawesome-free/js/all.min';
import Header from '../components/header';
import Home from './home';
import Curriculum from './cv';
import Portfolio from './portfolio';
import Footer from '../components/footer';
import './app.scss';

const App = () => {

    let setLanguage = localStorage.getItem('language')
    let scrollPos = 0;

    i18n.changeLanguage(setLanguage);

    function loading() {
        document.querySelector('body').classList.add('loaded')
    }

    setTimeout(loading,5000)

    window.addEventListener('scroll', function() {
        scrollPos = window.pageYOffset
        if(scrollPos > 0) {
            document.querySelector('header').classList.add('smallHeader')
        } else {
            document.querySelector('header').classList.remove('smallHeader')
        }
    })
	
	function closeContainer(e) {
		e.target.closest('div').style.display = "none"
	}

    return (
        <BrowserRouter>
            <div id={"wrapper"}>
                <Header />
                <div id={"loading-screen"}></div>
                <Route exact path={"/"} component={Home} />
                <Route exact path={"/cv"} component={Curriculum} />
                <Route exact path={"/portfolio"} component={Portfolio} />
                <Footer />
            </div>
			<div id={"image-container"} onClick={closeContainer}></div>
        </BrowserRouter>
    );
}

export default App;
