import React from 'react';
import { useTranslation } from 'react-i18next';
import './work-block.scss';

const WorkBlock = (props) => {

    const { t } = useTranslation()

    let directionsHover = ['top','left','right','bottom']
	
	function showDetail(e) {
		let getImageDetail = e.target.closest('.work-in').dataset.image
		let getImageDetailTitle = e.target.closest('.work-in').dataset.title
		let fillImageContainer = '<img src="'+getImageDetail+'" /><div>'+getImageDetailTitle+'</div>'
		document.getElementById('image-container').style.display = "flex"
		document.getElementById('image-container').innerHTML = fillImageContainer
		
	}

    return (
        <>
            <div className={"work col-12 col-sm-6 col-lg-3"}>
                <div className={"work-in " + directionsHover[Math.floor((Math.random()) * directionsHover.length)]} data-image={"images"+props.image} data-title={props.title} style={{backgroundImage: "url(images"+props.thumb+")"}} onClick={showDetail}>
                    <div className={"work-text"}>
                        <strong>{props.title}</strong><br />
                        <span className={"date"}>
                            {t('Created')}: {props.date}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WorkBlock;
