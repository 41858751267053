import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            "Simplicity is the ultimate sophistication": "Simplicity is the ultimate sophistication",
            "Design": "Design",
            "Service01": "Usability, simplicity, creative and still artful. A design fits the needs, branding and goals of the company. Yet keep it simple, modern and peaceful for the visitor.",
            "Development": "Development",
            "Service02": "Supported, fast, new technology. Many browsers are used, Edge, Chrome, Firefox, Safari, etc. Not all support the code the same way, development is therefor done to support all.",
            "Responsive": "Responsive",
            "Service03": "Desktop, tablet, mobile. There are many devices and more visit a website on a mobile device. Mobile first is the main focus for design and development.",
            "About me": "Every person who you meet would tell a different story about who you are. This because every person makes that you are having a different experience with the person and so act to this experience. All that I can say about myself is that i am born in 1985, around spring, like to wear black and mostly listen (classic) rock music. I was to become a laboratory technician, during this education we got Information Technology too and I became more interested in coding instead. So I ended up doing an education in Graphic Design on a school that, during my years there, changed the name to Mediacollege Amsterdam and graduated in 2008. After my failed HBO adventure, I found that I learn best by doing instead of only theory, so started to work as first an all-round Designer. From 2012 I am a full-time Front-end Developer at the current company I work, working mostly with HTML, CSS and jQuery/Javascript.",
            "Contact Title": "Nice to Meet You",
            "Latest Works": "Latest Works",
            "Created": "Created",
            "Follow or connect with me on": "Follow or connect with me on",
            "Websites": "Websites",
            "Prints": "Prints",
            "Illustrations": "Illustrations",
            "Characters": "Characters",
            "Videos": "Videos",
            "Study": "Study",
            "form-name": "Name",
            "form-email": "Email",
            "form-message": "Message",
            "form-send": "Send",
            "Personal Information": "Personal Information",
            "Family Name": "Family Name",
            "Usual Name": "Usual Name",
            "Email": "Email",
            "Year of Birth": "Year of Birth",
            "Martial Status": "Martial Status",
			"Unmarried": "Unmarried",
            "Gender": "Gender",
            "Male": "Male",
            "Nationality": "Nationality",
            "Dutch": "Dutch",
            "Drivers Licence": "Drivers Licence",
            "Yes": "Yes",
            "Education": "Education",
            "Edu04": "LOI Hogeschool, HBO, Communicatie en Multimediadesign - Certifitation: Digital Multimedia, Webdesign (CIW), Graphic design, Web Professional Foundation (CIW), Object Orientation Foundation (EXIN), Audio and Video, Interactive designing, Informtionsystems (EXIN), New media and digital culture, Advising, Projectmanagement",
            "Edu03": "Hogeschool van Amsterdam in Amsterdam, HBO, Interactive Media",
            "Edu02": "Mediacollege Amsterdam in Amsterdam, MBO, Interactive Designer, Degree",
            "Edu01": "Nova College in Beverwijk, MBO, Laboratoriumtechniek",
            "Edu00": "Paulusschool in Haarlem, MAVO, Degree ",
            "Work Experience": "Work Experience",
            "Work06": "Bratpack in Zwanenburg, Front End Developer ",
            "Work05": "New-It/VictorMundi.com in Noordbeemster, Designer",
            "Work04": "Dekamarkt in Haarlem, Part-time store employee",
            "Work03": "Autodealers.nl in Amsterdam, five months of internship and four weeks of holiday-tasks, Webdesigner",
            "Work02": "Blue Brother Image Sampling in Amsterdam, six months of internship, Interactive designer",
            "Work01": "Dekamarkt in Haarlem, six weeks of holiday-tasks, Store employee",
            "Work00": "Vialis in Haarlem, four weeks of holiday-tasks, Storage- / production employee",
            "Present": "Heden",
            "Programs": "Programs",
            "Other": "Other"
        }
    },
    nl: {
        translation: {
            "Simplicity is the ultimate sophistication": "Eenvoud is de ultieme verfijning",
            "Design": "Vormgeving",
            "Service01": "Bruikbaarheid, eenvoud, creatief en toch kunstzinnig. Een ontwerp past bij de behoeften, huisstijl en doelen van het bedrijf. Maar houd het simpel, modern en vredig voor de bezoeker.",
            "Development": "Ontwikkeling",
            "Service02": "Ondersteunding, snelheid, nieuwe technologie. Er worden veel browsers gebruikt, Edge, Chrome, Firefox, Safari, enz. Niet allemaal ondersteunen ze de code op dezelfde wijze, bij ontwikkeling wordt daarom ook gekeken naar de ondersteuning.",
            "Responsive": "Responsive",
            "Service03": "Desktop, tablet, mobiel. Er zijn vele apparaten en meer bezoekers van een website doen dat op een mobiel apparaat. Mobile first is daarom ook de belangrijkste focus voor ontwerp en ontwikkeling.",
            "About me": "Elke persoon die je ontmoet zal een ander verhaal vertellen over wie je bent, omdat elke persoon maakt dat je een andere ervaring met de persoon hebt en dus handelt naar deze ervaring. Het enige dat ik over mezelf kan vertellen is dat ik in 1985, rond de lente geboren ben, graag zwart draag en vooral luister naar (classic) rockmuziek. Ik stond op het punt om laborant te worden, tijdens deze opleiding kregen we ook informatietechnologie en raakte steeds meer geïnteresseerd in codering. Dus heb ik uiteindelijk een opleiding in grafisch ontwerp gevolgd op een school die, tijdens mijn jaren daar, de naam veranderde in Mediacollege Amsterdam en studeerde af in 2008. Na mijn mislukte HBO-avontuur ontdekte ik dat ik het beste leer door te doen in plaats van alleen theorie, dus begon ik te werken als eerste een allround ontwerper. Vanaf 2012 ben ik een full-time Front-end Developer bij het huidige bedrijf waar ik werk, waar ik voornamelijk met HTML, CSS en jQuery/Javascript werk.",
            "Contact Title": "Leuk je te ontmoeten",
            "Latest Works": "Laatste werken",
            "Created": "Gemaakt in",
            "Follow or connect with me on": "Volg of voeg me toe op",
            "Websites": "Websites",
            "Prints": "Drukwerk",
            "Illustrations": "Illustraties",
            "Characters": "Karikaturen",
            "Videos": "Video's",
            "Study": "Studie",
            "form-name": "Naam",
            "form-email": "E-mail",
            "form-message": "Bericht",
            "form-send": "Verstuur",
            "Personal Information": "Persoonlijke informatie",
            "Family Name": "Familie naam",
            "Usual Name": "Roepnaam",
            "Email": "E-mail",
            "Year of Birth": "Geboortejaar",
            "Martial Status": "Burgelijke stand",
			"Unmarried": "Ongehuwt",
            "Gender": "Geslacht",
			"Male": "Man",
            "Nationality": "Nationaliteit",
            "Dutch": "Nederlands",
            "Drivers Licence": "Rijbewijs",
            "Yes": "Ja",
            "Education": "Opleidingen",
            "Edu04": "LOI Hogeschool, HBO, Communicatie en Multimediadesign - Certificaten: Digitale multimedia, Webdesign (CIW), Grafische vormgeving, Web Professional Foundation (CIW), Object Orientation Foundation (EXIN), Audio en video, Interactieve vormgeving, Informatie systemen (EXIN), Nieuwe media en digitale cultuur, Adviseren, Projectmanagement",
            "Edu03": "Hogeschool van Amsterdam in Amsterdam, HBO, Interactieve Media",
            "Edu02": "Mediacollege Amsterdam in Amsterdam, MBO, Interactief vormgever, Diploma",
			"Edu01": "Nova College in Beverwijk, MBO, Laboratoriumtechniek",
			"Edu00": "Paulusschool in Haarlem, MAVO, Diploma ",
            "Work Experience": "Werk ervaring",
            "Work06": "Bratpack in Zwanenburg, Front-end developer",
            "Work05": "New-It/VictorMundi.com in Noordbeemster, designer",
            "Work04": "Dekamarkt in Haarlem, part-time winkel medewerker",
            "Work03": "Autodealers.nl in Amsterdam, vijf weken stage en vier weken vakantiewerk, webdesigner",
            "Work02": "Blue Brother Image Sampling in Amsterdam, zes weken stage, interactieve vormgever",
            "Work01": "Dekamarkt in Haarlem, zes weken vakantiewerk, winkel medewerker",
            "Work00": "Vialis in Haarlem, vier weken vakantiewerk, magazijn- / productie medewerker",
            "Present": "Heden",
            "Programs": "Programma's",
            "Other": "Overige"
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;